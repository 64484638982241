<template>
  <div class="thank-you-page">
    <v-container fluid fill-height class="mt-5">
      <v-card flat class="elevation-0">
        <CategoryTitle :category="category" />
        <v-card-actions
          class="justify-center"
          v-if="showLogin && !isAuthenticated"
        >
          <v-btn @click="handleLogin" color="primary" large depressed block>
            Accedi ora
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle";
import categoryMixins from "~/mixins/category";
import loginMixins from "~/mixins/login";
import EurospinCustomService from "@/service/eurospinCustomService";
// import Login from "@/components/Login.vue";

export default {
  name: "RegisterConfirmed",
  components: { CategoryTitle },
  props: { showLogin: { type: Boolean, default: false } },
  data() {
    return { errors: [], infos: [], confirmed: false };
  },
  mixins: [categoryMixins, loginMixins],
  methods: {
    async handleLogin() {
      if (await this.doLogin("RegistrationConfirmed")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            this.$router.push({
              name: "Home",
              path: "/"
            });
          }
        }
      }
    },
    async doConfirm(email, controlCode, newEmail) {
      let response = await EurospinCustomService.confirmRegistration(
        email,
        controlCode,
        newEmail
      );

      this.errors = response.errors;
      this.infos = response.infos;
      this.confirmed = response.status == 0;
    }
  },
  created() {
    if (this.$route.query.email) {
      this.doConfirm(
        this.$route.query.email,
        this.$route.query.token,
        this.$route.query.new_email
      );
    }
  }
};
</script>

<style></style>
